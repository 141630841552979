<template>
  <div>
    <!-- Header -->
    <MainHeader />
    <!-- end Header -->

    <!-- Main Banner -->
    <MainBanner hide-enquiry="true" />
    <!-- end Main Banner -->
    <div class="container py-8 mx-auto text-center">
      <h1 class="pb-6 text-3xl font-bold text-uni-red">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-lg md:px-0 opensan400">
        Thank you for your kind interest in Unihub Auto Sdn Bhd. We will get
        back to you as soon as possible.
      </p>
      <router-link to="/" class="block w-48 mx-auto bg-gray-900">
        <p class="block py-2 my-5 text-white rounded-lg">
          Back To Home
        </p>
      </router-link>
    </div>
    <!-- copyRight -->
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    MainHeader,
    MainBanner,
  },
};
</script>

<style>
</style>